$titleFmaily: 'Comfortaa';
$txtFamily: 'Inter';

// Size
$tiny: 10px;
$small: 11px;
$medium: 13px;
$large: 14px;
$xlarge: 16px;
$xxlarge: 36px;
$xxxlarge: 48px;

h1,h2,h3,h4,h5 {
  font-family: $titleFmaily;
  font-weight: 700;
  color: $main;
}

h1 {
  font-size: $xxxlarge;
  text-transform: uppercase;
  letter-spacing: 3px;
}

h2 {
  font-size: $xlarge;
  color: $blue;
  line-height: $large * 2;
}

h3 {
  font-size: $xlarge;
  text-align: left;
}

h4 {
  font-size: $large;
  color: $main;
}

p, li, a {
  font-family: $txtFamily;
  font-size: $medium;
  font-weight: 600;
  color: $black;
}