$img-august: -450px;

body {
  overflow-x: hidden;
}

.container {
  width: 100vw;
  height: 100vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $main;
  text-align: center;
  min-height: 600px;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-image: url('/assets/triangle.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }

  .top-gradient, .bottom-gradient {
    position: fixed;
    height: 990px;
    z-index: 1;

    img {
      width: 1000px;
      height: 1000px;
    }

    &.top-gradient {
      top: $img-august;
      right: $img-august;
      transform: rotate(90deg);
    }

    &.bottom-gradient {
      bottom: $img-august;
      left: $img-august;
      transform: rotate(180deg);
    }
  }

}

p + p {
  margin: 0;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

button {
  width: 200px;
  background: rgba(0,0,0,0);
  border: 1px solid rgba(0,0,0,0);
  color: $white;
  padding: $s;
  border-radius: 50px;
  transition: 0.7s;
  font-weight: 600;

  &.small {
    font-size: $small;
    width: 100px;
  }

  &.primary {
    background: $blue;
    color: $white;
  }

  &.ja {
    background-color: $green;
  }

  &.neutral {
    background-color: $main;
  }

  &.nej {
    background-color: $red;
  }

  &:hover, &.secondary {
    background: $black;
    color: $white;
    border: 1px solid $black;
    transition: 0.7s;
  }
}

span.primary, p.primary {
  color: $main;
  font-weight: 600;
  font-family: $txtFamily;
}

.title-row {
  display: flex; 
  flex-direction: row;
  align-items: center;
  margin-bottom: $xl;
  h3 {
    flex: 1;
  }
}

.shadow {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(170,170,170,1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(170,170,170,1);
  box-shadow: 0px 0px 5px 0px rgba(170,170,170,1);
}

@media only screen and (max-width: 600px) {
  .container {
    min-height: auto;
  }
}