.start-section {
  z-index: 1;

  img {
    width: 400px;
  }

  p {
    margin-top: $xxl;
    text-align: center;
  }

  h1, p {
    color: $blue;
  }

  button {
    margin-top: $xxl;
  }
}