.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $l;
  padding-left: $xxl;
  padding-right: $xxl;
  align-items: center;
  display: flex; 
  flex-direction: row;
  .icon-list {
    display: flex;
    flex-direction: row;
    flex: 1;
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      margin-right: $m;
    }
  }

  .represent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    img {
      margin-right: 0;
      width: 60px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .icon-list {
    max-width: 200px;
    flex-wrap: wrap;
  }
}