.question-section {
  z-index: 1;
  background-color: $white;
  width: 80vw;
  height: 60vh;
  border-radius: 10px;
  max-width: 800px;
  min-height: 600px;
  padding: $xxl * 2;
  display: flex;
  flex-direction: column;

  h2 {
    text-align: left;
  }

  .desc-blk {
    h3 {
      font-size: $large;
    }
    >div {
      max-height: 260px;
      overflow: scroll;
      padding: $xl;
    }
    p {
      text-align: left;
    }
    a {
      color: $blue;
    }
  }

  .answer-btns {
    margin-top: $xxl;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    button {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 600px) {
  .question-section {
    padding: $xl;
    h2 {
      font-size: $medium;
      line-height: $medium + 10;
    }
  }
}