.result-section {
  z-index: 1;
  background-color: $white;
  width: 80vw;
  height: 60vh;
  border-radius: 10px;
  max-width: 800px;
  max-height: 600px;
  min-height: 600px;
  padding: $xxl * 2;
  display: flex;
  flex-direction: column;

  .result-data {
    max-height: 380px;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: $xl;
  }

  .result-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: $s;
    padding-bottom: $s;

    p {
      width: 220px;
      text-align: left;
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      margin-right: $xxl;
    }
  }
}

@media only screen and (max-width: 600px) {
  .result-section {
    padding: $xl;
    .result-row {
      flex-wrap: wrap;
      p {
        width: 100%;
      }
    }
  }
}