.restart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: $xl;
  cursor: pointer;
  align-items: center;
  p {
    margin-left: $s;
  }
  img {
    width: 15px;
    height: 16px;
  }
}

.bottom-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
}