.analysis-section {
  z-index: 1;
  background-color: $white;
  width: 80vw;
  height: 60vh;
  border-radius: 10px;
  max-width: 800px;
  max-height: 600px;
  min-height: 600px;
  padding: $xxl * 2;
  display: flex;
  flex-direction: column;

  .analysis-blk {
    padding-top: $s;
    padding-bottom: $s;
    max-height: 500px;
    overflow-y: scroll;
    .question {
      text-align: left;
      padding-bottom: $xl;
    }

    .icon-list {
      display: flex;
      flex-direction: row;
      padding-top: $s;
      padding-bottom: $s;
      overflow-x: scroll;
      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        margin-right: $xxl;
        opacity: 0.3;
        cursor: pointer;
        &.active {
          opacity: 1;
        }
      }
    }

    .party-ans {
      padding: $xl;
      width: 98%;
      position: relative;
      left: 5px;
      margin-top: $s;
    }
  }
}

@media only screen and (max-width: 600px) {
  .analysis-section {
    padding: $xl;
    h2 {
      font-size: $medium;
      line-height: $medium + 10;
      margin-bottom: $s;
    }
  }
}